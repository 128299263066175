import type { VehiclePictureType } from '@yescapa-dev/ysc-api-js/legacy'

export const imageTypeOrder: VehiclePictureType[] = [
  '3_4_front',
  'bed_1',
  'dining_area',
  'kitchenette',
  'cockpit',
  'shower_int',
  'back',
  'bed_2',
  'other',
]

export const vehicleFormPhotoOrder: VehiclePictureType[] = ['3_4_front', 'back', 'cockpit', 'dining_area', 'kitchenette', 'shower_int', 'bed_1', 'bed_2']
